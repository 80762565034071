import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import KtLayout from '@solid-ui-blocks/KtLayout/KtLayout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import CanliDestek from '@solid-ui-blocks/CanliDestek/CanliDestek'

const MaksimumGuvenlik = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)


  return (
    <KtLayout prp={props} cnt={content} lightmi={false} >
      <Seo title='Ambalaj Modellerinde Hızlı Arama -  Die Cut Models Security System' />
      <Divider space='5' />
      <h3 style={{ color: "black", textAlign: "center" }}>Maksimum Güvenlik</h3>
      <div style={{ marginLeft: "20%", marginRight: "20%" }} >
        <div style={{ color: "black", fontSize: "18px" }}>&nbsp;&nbsp;&nbsp;&nbsp;Yüksek güvenilirlikli platformlarda son teknoloji ile geliştirilmiş ve gelişmeye devam eden, yalnızca doğrulama kodu ile giriş yapabileceğiniz size özel ekranlar.</div>
      </div>
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <CanliDestek></CanliDestek>
    </KtLayout>
  )
}

export const query = graphql`
  query innerpageMaksimumGuvenlikContent { 
    allBlockContent(filter: { page: { in: ["homepage/saas-v2", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    } 
  }
`

export default MaksimumGuvenlik
